
export default {
    computed: {
        storageUrl() {
            return process.env.STORAGE_URL;
        },
        user() {
            return this.$store.state.user;
        },
        login() {
            return process.env.LOGIN_URL;
        }
    },
    methods: {
        refresh() {
            window.location.reload(true);
        }
    },
    async mounted() {
        this.$i18n.setLocale(this.$store.state.user.language);
        const workbox = await window.$workbox;
        if (workbox) {
            workbox.addEventListener("installed", event => {
                if (event.isUpdate) {
                    this.$bvToast.show("update-toast");
                }
            });
        }
        const now = Date.now();
        if (now > this.user.exp * 1000) {
            this.$bvModal.show("session_timeout");
        }
    },
    async middleware({ store, redirect, route, $axios }) {
        if (store.state.user.sections && store.state.user.account === "student") {
            if (!store.state.user.section && store.state.user.sections.length === 1) {
                await $axios
                    .$get("/section")
                    .then(data => {
                        const user = { ...store.state.user };
                        user.section = data;
                        store.commit("user", user);
                    })
                    .catch(err => {
                        console.error(err);
                    });
                return redirect(route.fullPath);
            } else if (!store.state.user.section) {
                return redirect(`${process.env.SS_URL}/account/sections?platform=v1&next=${route.path}`);
            }
        }
    }
};
