export const state = () => ({
    glossary: null,
    glossaryLastUpdated: 0,
    glossaryLang: 'en',
    blockly: true
});

export const mutations = {
    glossary(state, glossary) {
        state.glossaryLastUpdated = Date.now();
        state.glossaryLang = glossary.lang;
        state.glossary = glossary.data;
    },
    blockly(state, blockly) {
        state.blockly = blockly;
    }
};