import { render, staticRenderFns } from "./NextActivity.vue?vue&type=template&id=0bff4e84&scoped=true&"
import script from "./NextActivity.vue?vue&type=script&lang=js&"
export * from "./NextActivity.vue?vue&type=script&lang=js&"
import style0 from "./NextActivity.vue?vue&type=style&index=0&id=0bff4e84&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bff4e84",
  null
  
)

export default component.exports