
export default {
    props: {
        data: Object
    },
    data() {
        return {
            showPreview: false,
            shown: false,
            isPseudo: false
        };
    },
    computed: {
        language() {
            return this.$store.state.content.lesson.language;
        }
    },
    methods: {
        viewPseudo() {
            this.isPseudo = !this.isPseudo;
            if (this.isPseudo) {
                this.$refs.editor.setCode(this.data.pseudo);
            } else {
                this.$refs.editor.setCode(this.data.code);
            }
        },
        toPreview(code) {
            const preview = this.$refs.preview;
            const doc = preview.contentWindow.document;
            doc.open();
            doc.write(this.isPseudo ? this.data.code : code);
            doc.close();
            setTimeout(() => {
                const el = this.$refs.previewContainer;
                el && el.scrollIntoView({ behavior: "smooth", block: "center" });
            }, 10);
            return code;
        },
        loadPreview() {
            this.$refs.editor.getCode();
            this.$store.commit("content/progress", "examples");
            this.showPreview = true;
        },
        copyCode() {
            this.$refs.editor.copy();
            const el = this.$refs.copyButton;
            el.disabled = true;
            el.innerHTML = this.$tf("Copied!");
            setTimeout(() => {
                el.innerHTML = this.$tf("Copy");
                el.disabled = false;
            }, 2000);
        },
        showCode() {
            this.shown = true;
        }
    },
    mounted() {
        if (!this.data.hide) {
            this.shown = true;
        }
    }
};
