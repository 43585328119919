
const ACTIVITIES = [
    "pre_assessment",
    "video",
    "presentation",
    "textbook",
    "checkpoint",
    "quiz",
    "challenges",
    "puzzles",
    "games",
    "assessment"
];
const ORDER = {
    pre_assessment: 1,
    video: 2,
    presentation: 3,
    textbook: 4,
    checkpoint: 5,
    quiz: 6,
    challenges: 7,
    puzzles: 8,
    puzzle_challenges: 9,
    scenes: 10,
    blockspro_challenges: 11,
    games: 12,
    assessment: 13
};

export default {
    props: {
        size: {
            default: "large",
            type: String
        },
        next: Object,
        paddingY: {
            default: true,
            type: Boolean
        }
    },
    computed: {
        lesson() {
            return this.$store.state.content.lessons[this.$route.params.lesson] || {};
        },
        progress() {
            return this.lesson.progress || {};
        },
        currentActivity() {
            return this.$store.state.currentActivity;
        },
        currentActivityOrder() {
            return ORDER[this.currentActivity] || 0;
        },
        nextActivity() {
            if (this.next) return this.next;
            const isHome = this.$route.name === "lessons-lesson";
            if (!isHome && this.lesson.next && this.progress.finish) {
                return {
                    kind: "lesson",
                    url: `/lessons/${this.lesson.next.id}`,
                    text: this.$tf("Go to next lesson"),
                    icon: "home",
                    class: "bg-success text-white"
                };
            }
            if (
                this.lesson.video &&
                !this.lesson.presentations &&
                !this.lesson.presentations.length &&
                ((!isHome && this.currentActivityOrder < ORDER.video) || (isHome && !this.progress.video))
            ) {
                return {
                    kind: "video",
                    text: this.$tf("Watch video"),
                    icon: "play_circle_filled",
                    class: "green"
                };
            }
            if (
                this.lesson.presentations &&
                this.lesson.presentations.length &&
                ((isHome && !this.progress.presentations) ||
                    (!isHome && this.currentActivityOrder < ORDER.presentation))
            ) {
                return {
                    kind: "presentation",
                    text: this.$tf("View presentations"),
                    icon: "ondemand_video",
                    class: "pink"
                };
            }
            if (
                this.lesson.blocks &&
                this.lesson.blocks.length &&
                ((isHome && !this.progress.textbook) || (!isHome && this.currentActivityOrder < ORDER.textbook))
            ) {
                return {
                    kind: "read",
                    text: this.$tf("Read textbook"),
                    icon: "menu_book",
                    class: "purple"
                };
            }
            if (
                this.lesson.checkpoint &&
                ((isHome && !this.progress.checkpoint) || (!isHome && this.currentActivityOrder < ORDER.checkpoint))
            ) {
                return {
                    kind: "checkpoint",
                    url: `/lessons/${this.lesson.id}/checkpoint`,
                    text: this.$tf("Go to checkpoint"),
                    icon: "code",
                    class: "blue"
                };
            }
            if (
                this.lesson.quiz &&
                ((isHome && !this.progress.quiz) || (!isHome && this.currentActivityOrder < ORDER.quiz))
            ) {
                return {
                    kind: "quiz",
                    url: `/lessons/${this.lesson.id}/quiz`,
                    text: this.$tf("Go to quiz"),
                    icon: "format_list_bulleted",
                    class: "red"
                };
            }
            if (
                this.lesson.challenges &&
                this.lesson.challenges.length &&
                ((isHome && !this.progress.challenges) || (!isHome && this.currentActivityOrder < ORDER.challenges))
            ) {
                return {
                    kind: "challenges",
                    url: `/lessons/${this.lesson.id}/challenges`,
                    text: this.$tf("Go to challenges"),
                    icon: "emoji_objects",
                    class: "orange"
                };
            }
            if (
                this.lesson.puzzles &&
                this.lesson.puzzles.length &&
                ((isHome && !this.progress.puzzles) || (!isHome && this.currentActivityOrder < ORDER.puzzles))
            ) {
                return {
                    kind: "puzzles",
                    url: `/lessons/${this.lesson.id}/puzzles`,
                    text: this.$tf("Go to puzzles"),
                    icon: "extension",
                    class: "yellow"
                };
            }
            if (
                this.lesson.puzzle_challenges &&
                this.lesson.puzzle_challenges.length &&
                ((isHome && !this.progress.puzzle_challenges) || (!isHome && this.currentActivityOrder < ORDER.puzzle_challenges))
            ) {
                return {
                    kind: "puzzle builder challenges",
                    url: `/lessons/${this.lesson.id}/puzzlebuilders`,
                    text: this.$tf("Go to puzzle builder challenges"),
                    icon: "extension",
                    class: "blue"
                };
            }
            if (
                this.lesson.scenes &&
                this.lesson.scenes.length &&
                ((isHome && !this.progress.scenes) || (!isHome && this.currentActivityOrder < ORDER.scenes))
            ) {
                return {
                    kind: "Blocks challenges",
                    url: `/lessons/${this.lesson.id}/scenes`,
                    text: this.$tf("Go to Blocks challenges"),
                    icon: "nature_people",
                    class: "peach"
                };
            }
            if (
                this.lesson.blockspro_challenges &&
                this.lesson.blockspro_challenges.length &&
                ((isHome && !this.progress.blockspro_challenges) ||
                    (!isHome && this.currentActivityOrder < ORDER.blockspro_challenges))
            ) {
                return {
                    kind: "Blocks Pro Challenges",
                    url: `/lessons/${this.lesson.id}/blockspro`,
                    text: this.$tf("Go to Blocks Pro challenges"),
                    icon: "nature_people",
                    class: "peach"
                };
            }
            if (
                this.lesson.games &&
                this.lesson.games.length &&
                ((isHome && !this.progress.games) || (!isHome && this.currentActivityOrder < ORDER.games))
            ) {
                return {
                    kind: "games",
                    url: `/lessons/${this.lesson.id}/games`,
                    text: this.$tf("Go to games"),
                    icon: "games",
                    class: "dark"
                };
            }
            if (
                this.lesson.exam &&
                this.lesson.exam.id &&
                ((isHome &&
                    !this.$store.state.content.detailedAssessmentProgress[
                        `${this.lesson.exam.id}-${this.lesson.exam.try}`
                    ]) ||
                    (!isHome && this.currentActivityOrder < ORDER.assessment))
            ) {
                return {
                    kind: "assessment",
                    url: `/lessons/${this.lesson.id}/assessment`,
                    text: this.$tf("Go to assessment")
                };
            }
            if (isHome && this.lesson.next) {
                return {
                    kind: "lesson",
                    url: `/lessons/${this.lesson.next.id}`,
                    text: this.$tf("Go to next lesson"),
                    icon: "check",
                    class: "bg-success text-white"
                };
            }
            return {
                kind: "lesson",
                url: `/lessons/${this.lesson.id}`,
                text: this.$tf("Back to lesson"),
                icon: "home",
                class: "bg-info text-white"
            };
        }
    },
    methods: {
        navigate() {
            if (this.nextActivity.kind === "read") {
                if (this.$route.name !== "lessons-lesson") {
                    this.$router.push(`/lessons/${this.lesson.id}`);
                } else {
                    this.$nuxt.$emit("scroll-to-textbook");
                }
            } else if (this.nextActivity.kind === "video") {
                this.$emit("play-video");
                this.$nuxt.$emit("openVideo");
            } else if (this.nextActivity.kind === "presentation") {
                this.$emit("play-presentation");
                this.$nuxt.$emit("openPresentation");
            } else {
                this.$router.push(this.nextActivity.url);
            }
        },
        capitalize(kind) {
            return kind.charAt(0).toUpperCase() + kind.slice(1);
        }
    }
};
