
export default {
    data() {
        return {
            visible: false,
            points: null,
            activityName: null,
            activityKind: null,
            next: null,
            typingStats: null,
            stats: null,
            newProgress: false
        };
    },
    watch: {
        "$route.params.lesson"(newLesson, oldLesson) {
            this.visible = false;
        }
    },
    computed: {
        backUrl() {
            let url = `/lessons/${this.$route.params.lesson}`;
            if (this.$route.params.game) return url + "/games";
            if (this.$route.params.challenge) return url + "/challenges";
            if (this.$route.params.puzzle) return url + "/puzzles";
            if (this.$route.params.scene) return url + "/scenes";
            if (this.$route.params.blocksChallenge) return url + "/blockspro";
            if (this.$route.params.puzzlechallenge) return url + "/puzzlebuilders";
            return this.$route.path;
        },
        currentActivityKind() {
            if (this.$route.params.game) return this.$tf("Games");
            if (this.$route.params.challenge) return this.$tf("Challenges");
            if (this.$route.params.puzzle) return this.$tf("Puzzles");
            if (this.$route.params.scene) return this.$tf("Blocks");
            if (this.$route.params.blocksChallenge) return this.$tf("Blocks Pro Challenges");
            if (this.$route.params.puzzlechallenge) return this.$tf("Puzzle Builder Challenges");
            return this.activityKind;
        }
    },
    methods: {
        goBack() {
            this.visible = false;
            this.$router.push(this.backUrl);
        },
        show(activityDetails) {
            const { points, name, kind, next, stats, newProgress } = activityDetails;
            if (points) {
                this.points = points;
            } else if (this.$store.state.user.onlyCode) {
                this.points = 10;
            } else {
                this.points = null;
            }
            if (name) {
                this.activityName = name;
            } else {
                this.activityName = null;
            }
            if (kind) {
                this.activityKind = kind;
            } else {
                this.activityKind = "Section";
            }
            if (next) {
                this.next = next;
            } else {
                this.next = null;
            }
            if (stats) {
                this.typingStats = stats;
            } else {
                this.typingStats = null;
            }
            if (stats) {
                this.stats = stats;
            } else {
                this.stats = null;
            }
            if (newProgress) {
                this.newProgress = newProgress;
            } else {
                this.newProgress = null;
            }
            this.visible = true;
        }
    },
    mounted() {
        this.$nuxt.$on("notification", this.show);
    },
    beforeDestroy() {
        this.$nuxt.$off("notification");
    }
};
