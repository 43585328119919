export default (context, inject) => {
    inject('success', message => {
        window.$nuxt.$bvToast.toast(message, {
            title: context.app.$tf("Success"),
            variant: "success",
            solid: true
        });
    });
    inject('error', (message, hide = false) => {
        window.$nuxt.$bvToast.toast(message, {
            title: context.app.$tf("Error"),
            variant: "danger",
            solid: true,
            noAutoHide: !hide
        });
    });
    inject('copy', async text => {
        try {
            await navigator.clipboard.writeText(text).then(() => true);
        } catch (e) {
            const textarea = document.createElement('textarea');
            textarea.value = text;
            textarea.style.top = '0';
            textarea.style.left = '0';
            textarea.style.position = 'fixed';
            document.body.appendChild(textarea);
            textarea.focus();
            textarea.select();
            const result = document.execCommand('copy');
            document.body.removeChild(textarea);
            return result;
        }
    });
};