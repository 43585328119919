
export default {
    props: {
        data: Object
    },
    mounted() {
        this.getGlossaryLinks();
    },
    methods: {
        getGlossaryLinks() {
            const glossary = this.$store.state.localStorage.glossary;
            if (!glossary) {
                return setTimeout(this.getGlossaryLinks, 200);
            }
            if (this.$refs.p) {
                const links = this.$refs.p.querySelectorAll('#textbook a[href*="#"]');
                if (links.length > 0) {
                    links.forEach(link => {
                        const el = document.createElement("span");
                        const term = link.href.slice(link.href.lastIndexOf("#") + 1);
                        const item = glossary[term];
                        if (item) {
                            el.innerHTML = item.definition.replace(/</g, "&lt;").replace(/>/g, "&gt;");
                            link.appendChild(el);
                            this.setClasses({ target: link });
                            link.addEventListener("mouseover", this.setClasses);
                            link.addEventListener("focus", this.setClasses);
                        }
                        link.addEventListener("click", function (event) {
                            event.preventDefault();
                        });
                    });
                }
            }
        },
        setClasses(event) {
            const link = event.target;
            const el = link.getElementsByTagName("span")[0];
            if (!el) {
                return;
            }
            el.className = "";
            link.classList.add("term");
            link.classList.add("text-decoration-none");
            el.classList.add("definition");
            el.classList.add("shadow");
            const pixelsFromTop = link.parentNode.parentNode.offsetTop;
            const pixelsFromBottom =
                link.parentNode.parentNode.parentNode.offsetHeight -
                (link.parentNode.parentNode.offsetTop + link.parentNode.parentNode.offsetHeight);
            const pixelsFromLeft = link.offsetLeft;
            const pixelsFromRight = link.parentNode.offsetWidth - (link.offsetLeft + link.offsetWidth);
            const tooltipWidth = el.offsetWidth;
            const tooltipHeight = el.offsetHeight;
            if (pixelsFromLeft < tooltipWidth / 2 && pixelsFromTop < tooltipHeight) {
                el.classList.add("bottomright");
            } else if (pixelsFromRight < tooltipWidth / 2 && pixelsFromTop < tooltipHeight) {
                el.classList.add("bottomleft");
            } else if (pixelsFromRight < tooltipWidth / 2 && pixelsFromBottom < tooltipHeight) {
                el.classList.add("topleft");
            } else if (pixelsFromLeft < tooltipWidth / 2 && pixelsFromBottom < tooltipHeight) {
                el.classList.add("topright");
            } else if (pixelsFromTop < tooltipHeight) {
                el.classList.add("bottom");
            } else if (pixelsFromRight < tooltipWidth / 2) {
                el.classList.add("topleft");
            } else if (pixelsFromLeft < tooltipWidth / 2) {
                el.classList.add("topright");
            } else {
                el.classList.add("top");
            }
        }
    }
};
