
export default {
    data() {
        return {
            loading: false
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        units() {
            return this.$store.state.content.units;
        },
        courses() {
            const courseNames = [...new Set(this.units.map(o => o.course))];
            return courseNames.map(o => ({ name: o, units: this.units.filter(u => u.course === o) }));
        },
        activeIndex() {
            const unit = this.$store.state.content.unit;
            const index = this.courses.findIndex(o => o.units.find(u => u.id === unit.id));
            return index > 0 ? index : 0;
        }
    },
    methods: {
        load() {
            const units = this.$store.state.content.units;
            if (!units.length) {
                this.loading = true;
                this.$axios
                    .$get("/units")
                    .then(data => {
                        this.$store.commit("content/units", data);
                    })
                    .catch(err => {
                        console.error(err);
                    })
                    .then(() => (this.loading = false));
            }
        },
        async selectUnit(unit) {
            if (unit.id !== this.$store.state.content.unit.id) {
                this.loading = true;
                await this.$axios
                    .$get(`/get_unit/${unit.id}`)
                    .then(data => {
                        this.$store.commit("content/lesson", null);
                        this.$store.commit("content/unit", data);
                        if (this.$route.path !== "/lessons") {
                            this.$router.push("/lessons");
                        }
                    })
                    .catch(err => {
                        console.error(err);
                    });
                this.loading = false;
            }
            this.$refs.modal && this.$refs.modal.hide();
        }
    }
};
