
export default {
    name: "ImageBank",
    data() {
        return {
            currentPage: 1,
            imagePreviewUrl: null,
            perPage: 18,
            showCopied: false,
            searchQuery: null
        };
    },
    methods: {
        copyUrlToClipboard(imageUrl) {
            this.$copy(imageUrl);

            // Show brief notification that image URL has been copied.
            this.showCopied = true;
            setTimeout(() => {
                this.showCopied = false;
            }, 1000);
        },
        resetModal() {
            // Reset pagination after Modal is closed.
            this.currentPage = 1;
            // Close large preview in case it's open.
            this.imagePreviewUrl = null;
            // Clear search query.
            this.searchQuery = null;
        }
    },
    computed: {
        images() {
            if (!this.searchQuery) return this.$store.state.images;
            return this.$store.state.images.filter(url => {
                const imageNameRegEx = /images.skillstruck.com\/(?<name>.*?)\..*$/gi;
                const imageName = imageNameRegEx.exec(url).groups.name.toLowerCase();

                const keywords = this.searchQuery.split(/\s+/);
                // Check if each keyword matches image name
                for (const keyword of keywords) {
                    if (keyword && imageName.includes(keyword.toLowerCase())) {
                        return true;
                    }
                }
            });
        }
    }
};
