const defaultState = () => ({
    lessons: {},
    lesson: {},
    unit: {},
    progress: {},
    lessonProgress: {},
    lessons_flat: [],
    plans: [],
    track: {},
    currentPlan: {},
    currentActivity: null,
    units: [],
    challengeProgress: {
        challenges: {}
    },
    gameProgress: {
        games: {}
    },
    puzzleProgress: {
        puzzles: {}
    },
    puzzleChallengeProgress: {
        challenges: {}
    },
    sceneProgress: {
        scenes: {}
    },
    blocksproProgress: {
        challenges: {}
    },
    assessments: [],
    openExams: [],
    assessmentProgress: {},
    detailedAssessmentProgress: {},
    customAssessmentsLoaded: false,
    customAssessments: [],
    customAssessmentProgress: {}
});

export const state = () => defaultState();

export const mutations = {
    reset(state) {
        Object.assign(state, defaultState());
    },
    lesson(state, lesson) {
        if (!lesson || !lesson.id) {
            state.lesson = {};
            state.lessons = {};
            return;
        }
        lesson.examples = checkForExamples(lesson.blocks || []);
        lesson.tasks = setTasks(lesson);
        // get image from content if not set already
        if (!lesson.image) {
            lesson.image = getImage(lesson);
        }
        state.lessons[lesson.id] = lesson;
        state.lessons = { ...state.lessons };
        state.lesson = lesson;
        state.progress = lesson.progress || {};
    },
    lessons_flat(state, lessons) {
        state.lessons_flat = lessons;
    },
    lockLessons(state, lessons) {
        if (state.lessons_flat) {
            state.lessons_flat = state.lessons_flat.filter(o => !lessons.includes(o.id));
        }
        if (state.unit.lessons) {
            state.unit.lessons = state.unit.lessons.filter(o => !lessons.includes(o.id));
        }
    },
    switchLesson(state, lesson) {
        if (state.lesson.id != lesson.id) {
            state.lesson = lesson;
            state.progress = lesson.progress || {};
        }
    },
    updateLesson(state, lesson) {
        state.lessons[lesson.id] = { ...lesson };
        state.lessons = { ...state.lessons };
        state.lesson = { ...lesson };
        if (!lesson.progress) {
            lesson.progress = {};
        }
        state.progress = { ...state.progress, ...lesson.progress };
    },
    lessonProgress(state, progress) {
        state.lessonProgress = progress;
    },
    unit(state, unit) {
        state.unit = { ...state.unit, ...unit };
    },
    resetUnitLessons(state) {
        state.unit.lessons = undefined;
    },
    units(state, units) {
        state.units = units || [];
    },
    resetUnits(state) {
        state.units = [];
    },
    plans(state, plans) {
        state.plans = plans;
    },
    switchPlan(state, plan) {
        if (state.currentPlan.id != plan.id) {
            state.currentPlan = plan;
            state.progress = plan.progress || {};
        }
    },
    addPlan(state, plan) {
        const index = state.plans.findIndex(o => o.id === plan.id);
        if (index < 0) {
            state.plans.push(plan);
        } else {
            state.plans[index] = plan;
        }
        state.plans = [...state.plans];
        const planIndex = state.track.plans && state.track.plans.findIndex(o => o.id === plan.id);
        if (planIndex >= 0) {
            state.track.plans[index] = plan;
            state.track.plans = [...state.track.plans];
        } else if (planIndex < 0) {
            state.track.plans.push(plan);
        }
        state.track = { ...state.track };
    },
    currentPlan(state, plan) {
        if (plan.activities) {
            plan.activities = setActivities(plan);
        }
        state.currentPlan = plan;
    },
    removeCurrentPlan(state) {
        state.currentPlan = "";
    },
    updatePlan(state, plan) {
        const index = state.plans.findIndex(o => o.id === plan.id);
        if (index >= 0) {
            state.plans[index] = plan;
        } else {
            state.plans.push(plan);
        }
        state.currentPlan = plan;
        state.progress = plan.progress || {};
    },
    currentActivity(state, activity) {
        state.currentActivity = activity;
    },
    completeActivity(state) {
        const activity = state.currentActivity;
        const plan = state.currentPlan;
        if (activity && plan) {
            if (!plan.progress) {
                plan.progress = {};
            }
            if (['challenges', 'games', 'puzzles'].includes(activity.kind)) {
                plan.progress[activity.id] = (plan.progress[activity.id] || 0) + 1;
            } else {
                plan.progress[activity.id] = true;
            }
        }
    },
    completeLesson(state, lessonId) {
        state.lessons[lessonId].progress = { ...state.lessons[lessonId].progress, finish: true };
        if (!state.lessonProgress[lessonId]) {
            state.lessonProgress[lessonId] = { finished: Date.now() };
            state.lessonProgress = { ...state.lessonProgress };
        }
        if (state.lesson) {
            state.lesson.progress.finish = true;
        }
        state.lessons = { ...state.lessons };
    },
    progress(state, item) {
        if (typeof (item) === "object") {
            state.progress = item;
        } else {
            state.lesson.progress = { ...state.lesson.progress, [item]: true };
            if (state.lessons[state.lesson.id]) {
                state.lessons[state.lesson.id].progress = { ...state.lessons[state.lesson.id].progress, [item]: true };
            }
            state.progress = { ...state.progress, [item]: true };
        }
    },
    planProgress(state, item) {
        if (typeof (item) === "object") {
            state.progress = item;
        } else {
            state.currentPlan.progress = { ...state.currentPlan.progress, [item]: true };
            state.progress = { ...state.progress, [item]: true };
        }
    },
    challengeProgress(state, progress) {
        state.challengeProgress = progress;
    },
    updateChallengeProgress(state, challengeID) {
        state.challengeProgress.challenges[challengeID] = true;
        state.challengeProgress = { ...state.challengeProgress };
        if (state.lesson.progress && !state.lesson.progress.challenges) {
            state.lesson.progress.challenges = [challengeID];
        } else if (state.lesson.progress && !state.lesson.progress.challenges.includes(challengeID)) {
            state.lesson.progress.challenges.push(challengeID);
        }
        state.lesson = { ...state.lesson };
        state.lessons[state.lesson.id] = { ...state.lesson };
    },
    gameProgress(state, progress) {
        state.gameProgress = progress;
    },
    updateGameProgress(state, gameID) {
        state.gameProgress.games[gameID] = true;
        state.gameProgress = { ...state.gameProgress };
        if (state.lesson.progress && !state.lesson.progress.games) {
            state.lesson.progress.games = [gameID];
        } else if (state.lesson.progress && !state.lesson.progress.games.includes(gameID)) {
            state.lesson.progress.games.push(gameID);
        }
        state.lesson = { ...state.lesson };
        state.lessons[state.lesson.id] = { ...state.lesson };
    },
    puzzleProgress(state, progress) {
        state.puzzleProgress = progress;
    },
    updatePuzzleProgress(state, puzzleID) {
        state.puzzleProgress.puzzles[puzzleID] = true;
        state.puzzleProgress = { ...state.puzzleProgress };
        if (state.lesson.progress && !state.lesson.progress.puzzles) {
            state.lesson.progress.puzzles = [puzzleID];
        } else if (state.lesson.progress && !state.lesson.progress.puzzles.includes(puzzleID)) {
            state.lesson.progress.puzzles.push(puzzleID);
        }
        state.lesson = { ...state.lesson };
        state.lessons[state.lesson.id] = { ...state.lesson };
    },
    puzzleChallengeProgress(state, progress) {
        state.puzzleChallengeProgress = progress;
    },
    updatePuzzleChallengeProgress(state, challengeID) {
        state.puzzleChallengeProgress.challenges[challengeID] = true;
        state.puzzleChallengeProgress = { ...state.puzzleChallengeProgress };
        if (state.lesson.progress && !state.lesson.progress.puzzle_challenges) {
            state.lesson.progress.puzzle_challenges = [challengeID];
        } else if (state.lesson.progress && !state.lesson.progress.puzzle_challenges.includes(challengeID)) {
            state.lesson.progress.puzzle_challenges.push(challengeID);
        }
        state.lesson = { ...state.lesson };
        state.lessons[state.lesson.id] = { ...state.lesson };
    },
    updateSceneProgress(state, sceneID) {
        state.sceneProgress.scenes[sceneID] = true;
        state.sceneProgress = { ...state.sceneProgress };
        if (state.lesson.progress && !state.lesson.progress.scenes) {
            state.lesson.progress.scenes = [sceneID];
        } else if (state.lesson.progress && !state.lesson.progress.scenes.includes(sceneID)) {
            state.lesson.progress.scenes.push(sceneID);
        }
        state.lesson = { ...state.lesson };
        state.lessons[state.lesson.id] = { ...state.lesson };
    },
    updateBlocksproProgress(state, challengeId) {
        state.blocksproProgress.challenges[challengeId] = true;
        state.blocksproProgress = { ...state.blocksproProgress };
        if (state.lesson.progress && !state.lesson.progress.blockspro_challenges) {
            state.lesson.progress.blockspro_challenges = [challengeId];
        } else if (state.lesson.progress && !state.lesson.progress.blockspro_challenges.includes(challengeId)) {
            state.lesson.progress.blockspro_challenges.push(challengeId);
        }
        state.lesson = { ...state.lesson };
        state.lessons[state.lesson.id] = { ...state.lesson };
    },
    track(state, track) {
        state.track = track;
    },
    openExams(state, exams) {
        state.openExams = exams;
    },
    assessments(state, assessments) {
        state.assessments = assessments;
    },
    assessmentProgress(state, progress) {
        state.assessmentProgress = progress;
    },
    detailedAssessmentProgress(state, progress) {
        state.detailedAssessmentProgress = progress;
    },
    customAssessments(state, assessments) {
        state.customAssessments = assessments;
        state.customAssessmentsLoaded = true;
    },
    customAssessmentProgress(state, progress) {
        state.customAssessmentProgress = progress;
    },
};

const checkForExamples = blocks => {
    return Boolean(blocks.find(o => o.type === 'code'));
};

const getImage = lesson => {
    if (lesson.blocks) {
        const image = lesson.blocks.find(o => o.type === 'image');
        if (image) {
            return image.attrs ? image.attrs.src : image.data && image.data.url;
        }
        return null;
    }
};

const setActivities = plan => {
    plan.activities.forEach((activity, index) => {
        activity.uid = activity.id;
        if (activity.kind === 'textbook') {
            activity.stage = 'learn';
            activity.icon = 'book';
            activity.color = 'dark';
            activity.url = 'textbook';
            activity.id = 'textbook';
            activity.required = false;
        } else if (activity.kind === 'checkpoint') {
            activity.stage = 'test';
            activity.icon = 'flag-checkered';
            activity.color = 'primary';
            activity.url = 'checkpoint';
            activity.id = 'checkpoint';
            activity.required = true;
        } else if (activity.kind === 'quiz') {
            activity.stage = 'test';
            activity.icon = 'list-ol';
            activity.color = 'pink';
            activity.url = 'quiz';
            activity.id = 'quiz';
            activity.required = true;
        } else if (activity.kind === 'challenge') {
            activity.stage = 'explore';
            activity.icon = 'code';
            activity.color = 'red';
            activity.url = 'challenges';
            activity.id = 'challenges';
            activity.required = false;
        } else if (activity.kind === 'challenges') {
            activity.stage = 'explore';
            activity.icon = 'code';
            activity.color = 'red';
            activity.url = 'challenges';
            activity.id = 'challenges';
            activity.required = false;
        } else if (activity.kind === 'game') {
            activity.stage = 'explore';
            activity.icon = 'joystick';
            activity.color = 'blue';
            activity.url = 'games';
            activity.id = 'games';
            activity.required = false;
        } else if (activity.kind === 'games') {
            activity.stage = 'explore';
            activity.icon = 'joystick';
            activity.color = 'blue';
            activity.url = 'games';
            activity.id = 'games';
            activity.required = false;
        } else if (activity.kind === 'puzzle') {
            activity.stage = 'explore';
            activity.icon = 'puzzle-piece';
            activity.color = 'green';
            activity.url = 'puzzles';
            activity.id = 'puzzles';
            activity.required = false;
        } else if (activity.kind === 'puzzles') {
            activity.stage = 'explore';
            activity.icon = 'puzzle-piece';
            activity.color = 'green';
            activity.url = 'puzzles';
            activity.id = 'puzzles';
            activity.required = false;
        } else if (activity.kind === 'file') {
            activity.stage = 'test';
            activity.icon = 'flag-checkered';
            activity.color = 'primary';
            activity.url = '';
            activity.id = '';
            activity.required = false;
        }
        activity.order = index + 1;
    });
    // don't add a code playground if one already exists in plan.activities
    if (plan.activities.findIndex(o => o.id === 'playground') < 0) {
        plan.activities.push({
            id: 'playground',
            name: 'Code playground',
            url: 'playground',
            stage: 'explore',
            color: 'yellow',
            icon: 'shapes',
            active: true,
            order: plan.activities.length + 1,
            lesson: plan.id
        });
    }
    return plan.activities;
};

const setTasks = lesson => {
    const tasks = [];
    let order = 1;
    if (lesson.video) {
        tasks.push({
            id: 'video',
            kind: 'video',
            name: 'Video',
            url: 'textbook#video',
            stage: 'learn',
            color: 'orange',
            icon: 'video',
            order: order++,
            required: false
        });
    }
    tasks.push({
        id: 'textbook',
        kind: 'textbook',
        name: 'Textbook',
        url: 'textbook',
        stage: 'learn',
        color: 'dark',
        icon: 'book',
        order: order++,
        required: false
    });
    if (lesson.examples) {
        tasks.push({
            id: 'examples',
            kind: 'examples',
            name: 'Examples',
            url: 'textbook#examples',
            stage: 'learn',
            color: 'light',
            icon: 'code-branch',
            order: order++,
            required: false
        });
    }
    if (lesson.presentations && lesson.presentations.length) {
        tasks.push({
            id: 'presentation',
            kind: 'presentation',
            name: 'Presentation',
            url: lesson.presentations[0].url,
            stage: 'learn',
            color: 'grey',
            icon: 'arrow-square-up',
            order: order++,
            required: false
        });
    }
    if (lesson.checkpoint) {
        tasks.push({
            id: 'checkpoint',
            kind: 'checkpoint',
            name: 'Checkpoint',
            url: 'checkpoint',
            stage: 'test',
            color: 'primary',
            icon: 'flag-checkered',
            order: order++,
            required: true
        });
    }
    if (lesson.quiz) {
        tasks.push({
            id: 'quiz',
            kind: 'quiz',
            name: 'Quiz',
            url: 'quiz',
            stage: 'test',
            color: 'pink',
            icon: 'list-ol',
            order: order++,
            required: true
        });
    }
    if (lesson.challenges && lesson.challenges.length) {
        tasks.push({
            id: 'challenges',
            kind: 'challenges',
            name: 'Challenges',
            url: 'challenges',
            stage: 'explore',
            color: 'red',
            icon: 'code',
            order: order++
        });
    }
    if (lesson.puzzles && lesson.puzzles.length) {
        tasks.push({
            id: 'puzzles',
            kind: 'puzzles',
            name: 'Puzzles',
            url: 'puzzles',
            stage: 'explore',
            color: 'green',
            icon: 'puzzle-piece',
            order: order++
        });
    }
    if (lesson.games && lesson.games.length) {
        tasks.push({
            id: 'games',
            kind: 'games',
            name: 'Games',
            url: 'games',
            stage: 'explore',
            color: 'blue',
            icon: 'joystick',
            order: order++
        });
    }
    tasks.push({
        id: 'playground',
        kind: 'playground',
        name: 'Code playground',
        url: 'playground',
        stage: 'explore',
        color: 'yellow',
        icon: 'shapes',
        order: order++
    });
    return tasks;
};