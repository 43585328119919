
export default {
    data() {
        return {
            visible: false,
            name: "Launch Pad",
            icon: "launchpad",
            disabled: false,
            message: "",
            search: "",
            activeColorPicker: false
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        lesson() {
            return this.$store.state.content.lesson;
        },
        typingUrl() {
            return process.env.TYPING_URL;
        },
        sectionsUrl() {
            return `${process.env.SS_URL}/account/sections?platform=v1&next=${this.$route.path}`;
        },
        pageHasTutorial() {
            return this.$store.state.hasTutorial;
        },
        unit() {
            return this.$store.state.content.unit;
        },
        onHomePage() {
            return this.$route.path === "/";
        },
        isColorPickerVisible() {
            return (
                this.$route &&
                this.$route.name &&
                (this.$route.name.startsWith("lessons-lesson") || this.$route.name === "playground")
            );
        }
    },
    methods: {
        startTutorial() {
            this.show = false;
            this.$nuxt.$emit("tutorial");
        },
        previousLesson() {
            if (this.lesson.prev) {
                this.$router.push(`/lessons/${this.lesson.prev.id}`);
            }
            this.blur();
        },
        async nextLesson() {
            if (this.lesson.next) {
                if (this.user.demo && ![17, 21].includes(this.lesson.unit) && this.lesson.index >= 2) {
                    this.$bvToast.toast($tf("The next lesson is not available in the demo"), {
                        title: $tf("Lesson not available"),
                        variant: "warning"
                    });
                } else if (this.user.section && this.user.section.sequence) {
                    if (!this.$store.state.content.lessonProgress.ready) {
                        await this.$axios
                            .$get("/lessons/progress")
                            .then(data => {
                                data.ready = true;
                                this.$store.commit("content/lessonProgress", data);
                            })
                            .catch(err => {
                                console.error(err);
                            });
                    }
                    if (!this.$store.state.content.lessonProgress[this.lesson.id]) {
                        this.$error(this.$tf("The next lesson is locked until you complete the current one"));
                    } else {
                        this.$router.push(`/lessons/${this.lesson.next.id}`);
                    }
                } else {
                    this.$router.push(`/lessons/${this.lesson.next.id}`);
                }
            }
            this.blur();
        },
        blur() {
            this.$nextTick(() => {
                document.activeElement.blur();
            });
        },
        showUnits() {
            this.$refs.units && this.$refs.units.$refs.modal.show();
        },
        onHideResources(event) {
            if (this.$store.state.showColorPicker || this.activeColorPicker) {
                event.preventDefault();
            }
        },
        showColorPicker() {
            this.activeColorPicker = true;
            this.$store.commit("toggleColorPicker", true);
        },
        showImageBank() {
            if (!this.$store.state.images.length) {
                // Load images from file only if store is empty.
                this.$store.commit("getImages", require("~/static/images.json"));
            }
            this.$refs.imagebank.$refs.modal.show();
        },
        makeActive(active) {
            const index = active.indexOf("/", 1);
            active = active.substr(0, index > 0 ? index : active.length);
            const el = document.querySelector(`a.dropdown-item.platform-item[href="${active}"]`);
            if (el) {
                this.icon = el.lastElementChild.textContent;
                this.name = el.firstElementChild.textContent;
            } else {
                this.name = "Launch Pad";
                this.icon = "launchpad";
            }
        },
        searchKnowledgeBase() {
            const url = "https://support.skillstruck.com/knowledge/kb-search-results?term=" + this.search;
            window.location.href = url;
        },
        showCodingResourcesPage() {
            const url = "https://support.skillstruck.com/knowledge/coding-resources";
            window.location.href = url;
        },
        changeSection() {
            window.location.href = this.sectionsUrl;
        }
    },
    mounted() {
        this.makeActive(this.$route.path);
    }
};
