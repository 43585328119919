
export default {
    props: ["active"],
    data() {
        return {
            color: "#0f6bff",
            copied: false
        };
    },
    computed: {
        show() {
            return this.$store.state.showColorPicker;
        }
    },
    methods: {
        change(color) {
            this.color = color;
            document.getElementById("copy_btn").focus();
        },
        async copy() {
            await this.$copy(this.color);
            this.copied = true;
            setTimeout(() => {
                this.$store.commit("toggleColorPicker", false);
            }, 300);
        },
        onShow(event) {
            this.copied = false;
        },
        onShown(event) {
            this.$refs.input_colorpicker.click();
        },
        onHide(event) {
            this.hide();
        },
        hide() {
            this.$store.commit("toggleColorPicker", false);
            this.$emit("hide-picker");
        }
    }
};
