export const state = () => ({
    user: {
        first: 'Guest'
    },
    currentActivity: null,
    currentProject: {},
    files: [],
    hasTutorial: false,
    images: [],
    isLeftOpen: true,
    isPinnedDock: true,
    loading: true,
    submissions: [],
    showColorPicker: false
});

const compare = (a, b) => {
    a = a.name.toUpperCase();
    b = b.name.toUpperCase();
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
};

export const mutations = {
    user(state, user) {
        state.user = user;
    },
    loading(state, status) {
        state.loading = status;
    },
    hasTutorial(state, hasTutorial) {
        state.hasTutorial = hasTutorial;
    },
    currentProject(state, project) {
        state.currentProject = project;
        state.files = project.files;
    },
    savedFiles(state) {
        state.files.forEach(file => {
            delete file.changed;
        });
        state.files = [...state.files];
    },
    addFile(state, file) {
        state.files.push(file);
        state.files.sort(compare);
        state.files = [...state.files];
    },
    removeFile(state, file) {
        const index = state.files.findIndex(o => o.id === file.id);
        state.files.splice(index, 1);
        state.files = [...state.files];
    },
    updateFile(state, file) {
        const index = state.files.findIndex(o => o.id === file.id);
        state.files[index] = file;
        state.files = [...state.files];
    },
    submissions(state, submissions) {
        state.submissions = state.submissions.concat(submissions);
        state.submissions = [...state.submissions];
    },
    updateSubmission(state, submission) {
        const index = state.submissions.findIndex(o => o.activity_type == submission.activity_type && o.activity_id == submission.activity_id);
        state.submissions[index] = submission;
        state.submissions = [...state.submissions];
    },
    toggleIsLeftOpen(state, value) {
        state.isLeftOpen = value !== undefined ? value : !state.isLeftOpen;
    },
    toggleDock(state, val) {
        state.isPinnedDock = val;
    },
    currentActivity(state, activity) {
        state.currentActivity = activity;
    },
    getImages(state, images) {
        state.images = images;
    },
    toggleColorPicker(state, value) {
        state.showColorPicker = value === undefined ? !state.showColorPicker : value;
    }
};

// load user data into the store
export const actions = {
    nuxtServerInit({ commit }, { req }) {
        if (req.user) {
            if (req.user.account !== 'director') {
                req.user.section = undefined;
            }
            commit('user', req.user);
        }
    }
};