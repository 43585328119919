import * as Sentry from "@sentry/vue";

export default function ({ req, route, redirect, $axios, store, error }) {
    if (req && !req.user) {
        if (route.path !== '/login') {
            return redirect('/login?next=' + route.path);
        }
    }
    if (!store.state.user.id) {
        if (store.state.user.id === undefined) {
            return $axios.$get('/user').then(user => {
                store.commit('user', user);
                Sentry.setUser({ id: user.id, sectionId: user.section && user.section.id || null });
                if (user.onlyCode) {
                    // remove blockly as default for code playground
                    // store.commit('localStorage/blockly', true);
                }
                return;
            }).catch(() => {
                store.commit('user', { id: false });
                return error({ statusCode: 401 });
            });
        }
        return redirect('/login');
    }
};