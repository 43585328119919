
import "@lottiefiles/lottie-player";
export default {
    props: {
        data: Object
    },
    computed: {
        user() {
            return this.$store.state.user;
        }
    },
    data() {
        return {
            componentKey: 0,
            showPlay: true,
            showIntro: false,
            showOutro: false,
            showReplay: false,
            timeStarted: -1,
            timePlayed: 0,
            duration: 0
        };
    },
    methods: {
        forceRerender() {
            this.componentKey += 1;
        },
        playVideo() {
            this.timeStarted = new Date().getTime() / 1000;
            this.showReplay = false;
            this.showPlay = false;
            this.showOutro = false;
            this.showIntro = true;
            if (!this.data.title) return;
            setTimeout(() => {
                this.showIntro = false;
                const video = this.$refs.video;
                if (!video) return;
                video.addEventListener("ended", this.playOutro, false);
                video.controls = true;
                video.play();
            }, 3500);
        },
        getVideoLoaded() {
            const video = this.$refs.video;
            if (!video) return;
            video.addEventListener("playing", () => {
                this.timeStarted = new Date().getTime() / 1000;
            });
            video.addEventListener("ended", this.checkVideoComplete);
            video.addEventListener("pause", this.checkVideoComplete);
            if (video.readyState > 0) {
                this.duration = video.duration;
            } else {
                video.addEventListener("loadedmetadata", () => {
                    this.duration = video.duration;
                });
            }
        },
        checkVideoComplete() {
            const requiredTime = this.duration * 0.75;
            if (this.timeStarted > 0) {
                const playedFor = new Date().getTime() / 1000 - this.timeStarted;
                this.timeStarted = -1;
                this.timePlayed += playedFor;
            }
            if (this.timePlayed > requiredTime) {
                const id = this.$route.params.lesson;
                const lesson = this.$store.state.content.lessons[id];
                if (lesson) {
                    if (!lesson.progress) {
                        lesson.progress = {};
                    }
                    if (id && lesson.video && !lesson.progress.video) {
                        this.$models.Progress.saveActivity(Number(id), { activity: "video" }).catch(err => {
                            console.error("Error saving video progress", err);
                        });
                    }
                    const updatedProgress = { ...lesson.progress, video: true };
                    const updatedLesson = { ...lesson, progress: updatedProgress };
                    this.$store.commit("content/updateLesson", updatedLesson);
                }
            }
        },
        playOutro() {
            this.showOutro = true;
            setTimeout(() => {
                this.showReplay = true;
            }, 3000);
        }
    },
    mounted: function () {
        this.forceRerender();
    },
    destroyed() {
        const video = this.$refs.video;
        if (video) video.removeEventListener("ended", this.playOutro);
    }
};
